import { css, Global } from '@lux-ds/theming/dist/cjs/styles';

const BaseGlobalStyles: React.FC = () => (
  <Global
    styles={css`
      body {
        display: grid;
        grid-template-columns: minmax(61px, max-content) 1fr;
        height: 100vh;
        width: 100vw;
        margin: 0;
        overflow: hidden;
        position: relative;

        #root {
          height: 100vh;
          overflow-x: auto;
        }
      }
    `}
  />
);
export default BaseGlobalStyles;
